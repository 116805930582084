import { FC, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { routeConfig } from "shared/config/routeConfig/routeConfig";
import { ProtectedRoute } from "./ProtectedRoute";
import { MainLayout } from "shared/layouts/MainLayout";
import { SignInPage } from "pages/SignInPage";

interface AppRouterProps {}

const AppRouter: FC<AppRouterProps> = () => (
  <Routes>
    <Route path="/sign-in" element={<SignInPage />} />
    <Route element={<ProtectedRoute />}>
      <Route element={<MainLayout />}>
        {Object.values(routeConfig).map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={<Suspense fallback={"Loading ..."}>{element}</Suspense>}
          />
        ))}
      </Route>
    </Route>
  </Routes>
);

export default AppRouter;
