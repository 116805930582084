// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderTable_HeaderTable__izEHl {
  display: flex;
  width: 100%;
  background: var(--White, #FFF);
  padding: 12px 24px;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/HeaderTable/ui/HeaderTable.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;EACA,kBAAA;AACF","sourcesContent":[".HeaderTable {\n  display: flex;\n  width: 100%;\n  background: var(--White, #FFF);\n  padding: 12px 24px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderTable": `HeaderTable_HeaderTable__izEHl`
};
export default ___CSS_LOADER_EXPORT___;
