// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonExit_ButtonExit__D0WMs {
  display: flex;
  align-items: center;
  color: var(--White, #fff);
  font: var(--font-m);
}

.ButtonExit_iconExit__oogeD {
  margin-right: 8px;
}

.ButtonExit_email__kyx0z {
  color: var(--White, #fff);
  font: var(--font-s);
  opacity: 0.5;
  margin-left: 32px;
}`, "",{"version":3,"sources":["webpack://./src/features/logOut/ui/ButtonExit/ButtonExit.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".ButtonExit {\n  display: flex;\n  align-items: center;\n  color: var(--White, #fff);\n  font: var(--font-m);\n}\n\n.iconExit {\n  margin-right: 8px;\n}\n\n.email {\n  color: var(--White, #fff);\n  font: var(--font-s);\n  opacity: 0.5;\n  margin-left: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonExit": `ButtonExit_ButtonExit__D0WMs`,
	"iconExit": `ButtonExit_iconExit__oogeD`,
	"email": `ButtonExit_email__kyx0z`
};
export default ___CSS_LOADER_EXPORT___;
