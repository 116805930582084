// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionInfo_TransactionInfo__Ai5\\+t {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
  column-gap: 16px;
  padding: 20px 24px;
}

.TransactionInfo_field__nwwPK {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.TransactionInfo_field__nwwPK .TransactionInfo_title__V7Zjb {
  font: var(--font-m);
  font-weight: 700;
  color: #000;
  margin-bottom: 8px;
}
.TransactionInfo_field__nwwPK .TransactionInfo_value__0vNko {
  font: var(--font-m);
  text-align: end;
  font-weight: 400;
  color: #000;
}

.TransactionInfo_wrapperBtnConfirm__32vaQ {
  display: flex;
  align-items: start;
  justify-content: end;
}

.TransactionInfo_btnConfirmOperation__u0-4r {
  flex-grow: 0;
  padding: 14px 16px;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/TransactionInfo/TransactionInfo.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EAEA,2BAAA;EAEA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;AAIE;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AAHJ;;AAOA;EACE,aAAA;EACA,kBAAA;EAEA,oBAAA;AALF;;AAQA;EACE,YAAA;EACA,kBAAA;AALF","sourcesContent":[".TransactionInfo {\n  // display: grid;\n  display: flex;\n  // grid-template-columns: repeat(5, 1fr);\n  flex-direction: row-reverse;\n  // justify-content: end;\n  width: 100%;\n  height: 100%;\n  column-gap: 16px;\n  padding: 20px 24px;\n}\n\n.field {\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n\n  .title {\n    font: var(--font-m);\n    font-weight: 700;\n    color: #000;\n    margin-bottom: 8px;\n  }\n\n  .value {\n    font: var(--font-m);\n    text-align: end;\n    font-weight: 400;\n    color: #000;\n  }\n}\n\n.wrapperBtnConfirm {\n  display: flex;\n  align-items: start;\n  // justify-content: center;\n  justify-content: end;\n}\n\n.btnConfirmOperation {\n  flex-grow: 0;\n  padding: 14px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TransactionInfo": `TransactionInfo_TransactionInfo__Ai5+t`,
	"field": `TransactionInfo_field__nwwPK`,
	"title": `TransactionInfo_title__V7Zjb`,
	"value": `TransactionInfo_value__0vNko`,
	"wrapperBtnConfirm": `TransactionInfo_wrapperBtnConfirm__32vaQ`,
	"btnConfirmOperation": `TransactionInfo_btnConfirmOperation__u0-4r`
};
export default ___CSS_LOADER_EXPORT___;
