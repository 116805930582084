import { FC, memo } from "react";
import { Loader } from "shared/ui/Loader";

import cls from "./PendingPage.module.scss";

interface PendingPageProps {}

const PendingPage: FC<PendingPageProps> = memo(() => {
  return (
    <div className={cls.PendingPage}>
      <Loader
        width={70}
        height={70}
        borderBottomColor="#014963"
        borderColor="#03BAFC"
      />
    </div>
  );
});

export default PendingPage;
