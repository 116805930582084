import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { StateSchema } from "app/providers/StoreProvider";
import { IPeriod } from "shared/types/IPeriod";
import { IStatus } from "shared/types/IStatus";
import { getStatuses } from "../services/getStatuses";
import { getTransactionsExcel } from "../services/getTransactionsExcel";
import { TransactionsFilterSchema } from "../types/TransactionsFilterSchema";

export interface ISelectedSetting {
  name: string;
  value: string;
}

const getDateRange = (daysAgo = 0) => {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);

  const startDate = new Date(date);
  startDate.setHours(0, 0, 0);

  const endDate = new Date(date);
  endDate.setHours(23, 59, 59);

  return {
    startDate: startDate.getTime(),
    endDate: endDate.getTime(),
  };
};

const periods: IPeriod[] = [
  { id: 1, name: "Все время" },
  {
    id: 2,
    name: "Сегодня",
  },
  {
    id: 3,
    name: "Вчера",
  },
];

const defaultSelectedPeriod = { id: -1, name: "", startDate: 0, endDate: 0 };
const defaultSelectedStatus = { id: -1, name: "Все статусы" };

const initialState: TransactionsFilterSchema = {
  listLoadingData: [],
  listSuccessData: [],
  calendar: {
    startDate: 0,
    endDate: 0,
  },
  periods,
  selectedPeriod: periods[0],
  statuses: [],
  selectedStatuses: [defaultSelectedStatus],
  query: "",
};

export const transactionsFilterSlice = createSlice({
  name: "transactionsFilter",
  initialState,
  reducers: {
    changeDataCalendar: (state, action) => {
      const { startDate, endDate } = action.payload;
      state.calendar = {
        startDate: new Date(startDate).getTime(),
        endDate: new Date(endDate).getTime(),
      };
      state.selectedPeriod = defaultSelectedPeriod;
    },
    changeSelectedPeriod: (state, action: PayloadAction<IPeriod>) => {
      const { id } = action.payload;

      if (id === 1) {
        // Всё время
        state.calendar = {
          startDate: 0,
          endDate: Date.now(),
        };
      }

      if (id === 2) {
        // Сегодня
        state.calendar = getDateRange();
      }

      if (id === 3) {
        state.calendar = getDateRange(1);
      }

      state.selectedPeriod = action.payload;
    },
    toggleSelectedStatus: (state, action) => {
      const { id } = action.payload;

      const indexSelectedStatus = getIndexSelectedStatus(state, id);

      // 1. Нажимаем на неактивную кнопку 'Все статусы'. Все статусы становятся активными
      if (id === defaultSelectedStatus.id && indexSelectedStatus === -1)
        state.selectedStatuses = state.statuses;

      // 2. Нажимаем на активную кнопку 'Все статусы'. Все статусы становятся не активными
      if (id === defaultSelectedStatus.id && indexSelectedStatus !== -1)
        state.selectedStatuses = [];

      // 3. Нажимаем на любую другую кнопку кроме 'Все статусы', если при данный статус выбран.
      // Делаем данный статус не активным
      if (id !== defaultSelectedStatus.id && indexSelectedStatus !== -1) {
        state.selectedStatuses = state.selectedStatuses.filter(
          (selectedStatus) => {
            if (selectedStatus.id === -1) return false;
            return (
              selectedStatus.id !==
              state.selectedStatuses[indexSelectedStatus].id
            );
          }
        );
      }
      // 4. Нажимаем на любую другую кнопку кроме 'Все статусы', если при этом данный статус НЕ выбран.
      // Делаем данный статус активным
      if (id !== defaultSelectedStatus.id && indexSelectedStatus === -1) {
        state.selectedStatuses.push(action.payload);
      }
      // 5. Если мы выбираем все статусы (протыкивая каждый), не нажимая на саму кнопку 'Все статусы'. То
      // делаем эту кнопку ('Все статусы') активной.
      if (
        id !== defaultSelectedStatus.id &&
        state.selectedStatuses.length === state.statuses.length - 1
      ) {
        state.selectedStatuses.push(defaultSelectedStatus);
      }
    },
    changeSearch: (state, action) => {
      state.query = action.payload;
    },
    resetSettings: () => initialState,
  },
  extraReducers: (builder) => {
    // getStatuses
    builder.addCase(getStatuses.pending, (state, action) => {
      state.listLoadingData.push("statuses");
      state.listLoadingData = state.listLoadingData.filter(
        (nameLoadingData) => nameLoadingData !== "statuses"
      );
    });
    builder.addCase(getStatuses.fulfilled, (state, action) => {
      state.statuses = [defaultSelectedStatus, ...action.payload];
      state.selectedStatuses = [defaultSelectedStatus, ...action.payload];
      state.listLoadingData = state.listLoadingData.filter(
        (nameData) => nameData !== "statuses"
      );
      if (!state.listSuccessData.includes("statuses")) {
        state.listSuccessData.push("statuses");
      }
    });

    // getTransactionsExcel
    builder.addCase(getTransactionsExcel.pending, (state, action) => {
      state.listLoadingData.push("transactionsExcel");
      state.listLoadingData = state.listLoadingData.filter(
        (nameLoadingData) => nameLoadingData !== "transactionsExcel"
      );
    });
    builder.addCase(getTransactionsExcel.fulfilled, (state, action) => {
      state.listLoadingData = state.listLoadingData.filter(
        (nameData) => nameData !== "transactionsExcel"
      );
      if (!state.listSuccessData.includes("transactionsExcel")) {
        state.listSuccessData.push("transactionsExcel");
      }
    });
  },
});

export const selectTransactionsQuery = (state: StateSchema) => {
  return state.transactionsFilter.query;
};

export const selectPeriods = (state: StateSchema) =>
  state.transactionsFilter.periods;
export const selectSelectedPeriod = (state: StateSchema) =>
  state.transactionsFilter.selectedPeriod;
export const selectStatuses = (state: StateSchema) =>
  state.transactionsFilter.statuses;
export const selectSelectedStatuses = (state: StateSchema) =>
  state.transactionsFilter.selectedStatuses;

export const selectListLoadingData = (state: StateSchema) =>
  state.transactionsFilter.listLoadingData;

export const selectIsLoadingFilterData =
  (nameData: string) => (state: StateSchema) => {
    return state.transactionsFilter.listLoadingData.includes(nameData);
  };

export const selectIsSuccessFilterData =
  (nameData: string) => (state: StateSchema) => {
    return state.transactionsFilter.listSuccessData.includes(nameData);
  };

export const {
  changeSelectedPeriod,
  changeDataCalendar,
  toggleSelectedStatus,
  resetSettings,
  changeSearch,
} = transactionsFilterSlice.actions;

export default transactionsFilterSlice.reducer;

export const { actions: transactionsFilterActions } = transactionsFilterSlice;

const getIndexSelectedStatus = (
  state: TransactionsFilterSchema,
  id: number
) => {
  const supportFunction = (selectedStatus: IStatus) => selectedStatus.id === id;
  return state.selectedStatuses.findIndex(supportFunction);
};
