import { FC, PropsWithChildren, memo, useEffect } from "react";
import classNames from "classnames";

import { Outlet } from "react-router-dom";
import { SideBar } from "widgets/SideBar";
import { getStatuses } from "entities/Transactions";
import { useAppDispatch } from "shared/lib/hooks/useAppDispatch";

import cls from "./MainLayout.module.scss";

interface MainLayoutProps extends PropsWithChildren {
  className?: string;
}

const MainLayout: FC<MainLayoutProps> = memo((props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStatuses());
  }, [dispatch]);

  return (
    <div className={classNames(cls.MainLayout, [className])}>
      <SideBar />
      <main className={cls.main}>
        <Outlet />
      </main>
    </div>
  );
});

export default MainLayout;
