// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_Loader__cBo0o {
  border: 4px solid #FFF;
  border-bottom-color: #03BAFC;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: Loader_rotation__lJVTx 1s linear infinite;
}

@keyframes Loader_rotation__lJVTx {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Loader/ui/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,4BAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,oDAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":[".Loader {\n  border: 4px solid #FFF;\n  border-bottom-color: #03BAFC;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loader": `Loader_Loader__cBo0o`,
	"rotation": `Loader_rotation__lJVTx`
};
export default ___CSS_LOADER_EXPORT___;
