import { createSlice } from "@reduxjs/toolkit";
import { getBalanceLastDays } from "../services/getBalanceLastDays";
import { StateSchema } from "app/providers/StoreProvider";
import { getBalanceByPeriod } from "../services/getBalanceByPeriod";

export interface AnalyticsSchema {
  balance: {
    value?: number;
    yesterday?: number;
    today?: number;
  };
  loadingList: string[];
  errorList: string[];
}

const initialState: AnalyticsSchema = {
  balance: {
    value: undefined,
    yesterday: undefined,
    today: undefined,
  },
  loadingList: [],
  errorList: [],
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBalanceLastDays.pending, (state) => {
      const index = state.errorList.indexOf("lastDays");
      if (index !== -1) {
        state.loadingList.splice(index, 1);
      }
      if (state.loadingList.indexOf("lastDays") === -1) {
        state.loadingList.push("lastDays");
      }
    });
    builder.addCase(getBalanceLastDays.fulfilled, (state, action) => {
      const { yesterday, today } = action.payload;
      state.balance.today = today;
      state.balance.yesterday = yesterday;
      const index = state.loadingList.indexOf("lastDays");
      if (index !== -1) {
        state.loadingList.splice(index, 1);
      }
    });
    builder.addCase(getBalanceLastDays.rejected, (state) => {
      const indexLoadingList = state.loadingList.indexOf("lastDays");
      const indexErrorList = state.loadingList.indexOf("lastDays");
      if (indexLoadingList !== -1) {
        state.loadingList.splice(indexLoadingList, 1);
      }
      if (indexErrorList === -1) {
        state.errorList.push("lastDays");
      }
    });

    builder.addCase(getBalanceByPeriod.pending, (state) => {
      const index = state.errorList.indexOf("byPeriod");
      if (index !== -1) {
        state.loadingList.splice(index, 1);
      }
      if (state.loadingList.indexOf("byPeriod") === -1) {
        state.loadingList.push("byPeriod");
      }
    });
    builder.addCase(getBalanceByPeriod.fulfilled, (state, action) => {
      const { balance } = action.payload;
      state.balance.value = balance;
      const index = state.loadingList.indexOf("byPeriod");
      if (index !== -1) {
        state.loadingList.splice(index, 1);
      }
    });
    builder.addCase(getBalanceByPeriod.rejected, (state) => {
      const indexLoadingList = state.loadingList.indexOf("byPeriod");
      const indexErrorList = state.loadingList.indexOf("byPeriod");
      if (indexLoadingList !== -1) {
        state.loadingList.splice(indexLoadingList, 1);
      }
      if (indexErrorList === -1) {
        state.errorList.push("byPeriod");
      }
    });

  },
});

type balanceName = "lastDays" | "byPeriod";

export const getAnalyticsIsLoadingByName = (key: balanceName) => (state: StateSchema) => {
  return state.analytics.loadingList.indexOf(key) !== -1;
};

export const getAnalyticsIsErrorByName = (key: balanceName) => (state: StateSchema) => {
  return state.analytics.errorList.indexOf(key) !== -1;
};

export const getAnalyticsBalance = (state: StateSchema) => {
  return state.analytics.balance;
}

export const { actions: analyticsActions } = analyticsSlice;
export const { reducer: analyticsReducer } = analyticsSlice;
