import {
  ChangeEvent,
  FC,
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { NumericFormat, PatternFormat } from "react-number-format";

import cls from "./Input.module.scss";

type HTMLInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value" | "onChange" | "onBlur"
>;

export enum ThemeInput {
  TEST = "test",
  POPUP = "popup",
}

interface InputProps extends HTMLInputProps {
  className?: string;
  value?: string | number;
  autofocus?: boolean;
  readonly?: boolean;
  fullWidth?: boolean;
  name?: string;
  theme?: ThemeInput;
  label?: string;
  addOnLeft?: ReactNode;
  addOnRight?: ReactNode;
  type?: string;
  textError?: string;
  mask?: string;
  format?: string;
  valueIsNumericString?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (value: any) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

type Mods = Record<string, boolean | string | undefined>;

interface InputElementProps {
  mask?: string;
  format?: string;
  [key: string]: any;
}

const InputElement = forwardRef((props: InputElementProps, ref) => {
  const { mask = "", format = "", ...otherProps } = props;
  if (mask === "amount") {
    return (
      <NumericFormat
        {...otherProps}
        thousandSeparator=" "
        allowNegative={false}
      />
    );
  }
  if (format) {
    return <PatternFormat mask={mask} format={format} {...otherProps} />;
  }
  return <input {...otherProps} />;
});

const Input: FC<InputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    className,
    value,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    autofocus,
    fullWidth,
    label,
    name,
    addOnLeft,
    addOnRight,
    textError,
    mask,
    theme = ThemeInput.TEST,
    ...otherProps
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.();
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.();
  };

  useEffect(() => {
    if (autofocus) {
      setIsFocused(true);
      inputRef.current?.focus();
    }
  }, [autofocus]);

  const mods: Mods = {
    [cls[theme]]: true,
    [cls.focused]: isFocused,
    [cls.fullWidth]: fullWidth,
  };

  const labelElement = label && (
    <label htmlFor={name} className={cls.label}>
      {label}
    </label>
  );

  const addOnLeftElement = addOnLeft && (
    <div className={cls.addOnLeft}>{addOnLeft}</div>
  );

  const addOnRightElement = addOnRight && (
    <div className={cls.addOnRight}>{addOnRight}</div>
  );

  return (
    <div className={classNames(cls.InputWrapper, mods, [className])}>
      {labelElement}
      <div className={cls.container}>
        {addOnLeftElement}
        <InputElement
          mask={mask}
          id={name}
          name={name}
          value={value}
          ref={inputRef}
          className={cls.input}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          {...otherProps}
        />
        {addOnRightElement}
        <span className={classNames(cls.error, { [cls.active]: textError })}>
          {textError}
        </span>
      </div>
    </div>
  );
};

export default Input;
