import { FC } from "react";

import { Pagination } from "features/ui/Pagination";
import { useSelector } from "react-redux";

import { selectTransactionsPageCount } from "entities/Transactions/model/selectors/selectTransactionsPageCount";
import { selectTransactionsPage } from "entities/Transactions/model/selectors/selectTransactionsPage";
import { selectTransactionsIsSuccess } from "entities/Transactions/model/selectors/selectTransactionsIsSuccess";
import { selectTransactions } from "entities/Transactions/model/selectors/selectTransactions";

// import cls from "./PaginationTable.module.scss";
import { useDispatch } from "react-redux";
import { transactionActions } from "entities/Transactions/model/slices/transactionsReducer";

interface PaginationTableProps {}

export const PaginationTable: FC<PaginationTableProps> = () => {
  const dispatch = useDispatch();

  const transactions = useSelector(selectTransactions);
  const currentPage = useSelector(selectTransactionsPage);
  const pageCount = useSelector(selectTransactionsPageCount);
  const isSuccess = useSelector(selectTransactionsIsSuccess);

  const isShowPagination = isSuccess && transactions.length !== 0;

  const correctCurrentPage = currentPage + 1;

  const handleChangePage = (page: number) => {
    dispatch(transactionActions.setPage(page - 1));
  };

  if (!isShowPagination) return null;

  return (
    <div>
      <Pagination
        pageCount={pageCount}
        currentPage={correctCurrentPage}
        onChange={handleChangePage}
      />
    </div>
  );
};
