import { RouteProps } from "react-router-dom";

import { NotFoundPage } from "pages/NotFoundPage";
import { TransactionsPage } from "pages/TransactionsPage";
import { AnalyticsPage } from "pages/AnalyticsPage";

export enum AppRoutes {
  TRANSACTIONS = "transactions",
  ANALYTICS = "analytics",
  NOT_FOUND = "not_found",
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.TRANSACTIONS]: "/",
  [AppRoutes.ANALYTICS]: "/analytics",
  [AppRoutes.NOT_FOUND]: "*",
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.TRANSACTIONS]: {
    path: RoutePath.transactions,
    element: <TransactionsPage />,
  },
  [AppRoutes.ANALYTICS]: {
    path: RoutePath.analytics,
    element: <AnalyticsPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
};
