import { FC, SVGProps, memo, useState } from "react";

import { NavLink, LinkProps } from "react-router-dom";
import cls from "./AppLink.module.scss";
import classNames from "classnames";
import { Icon } from "shared/ui/Icon";

export enum AppLinkTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

interface AppLinkProps extends LinkProps {
  className?: string;
  theme?: AppLinkTheme;
  icon?: FC<SVGProps<SVGSVGElement>>;
  iconActive?: FC<SVGProps<SVGSVGElement>>;
  testId?: string;
}

const AppLink: FC<AppLinkProps> = memo((props) => {
  const {
    to,
    className,
    children,
    theme = AppLinkTheme.PRIMARY,
    icon,
    iconActive,
    testId,
    ...otherProps
  } = props;

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const getCorrectContent = ({ isActive }: { isActive: boolean }) => {
    if (icon) {
      return (
        <div className={cls.contentLink}>
          <Icon
            Svg={isActive ? iconActive : icon}
            opacity={isHover ? 1 : isActive ? 1 : 0.5}
          />
          <p className={cls.wrapperTextLink}>
            <span className={classNames(cls.textLink)}>{children}</span>
          </p>
        </div>
      );
    }
    if (children) return <p className={cls.textLink}>{children}</p>;
    return null;
  };

  return (
    <NavLink
      className={({ isActive }) =>
        classNames(cls.AppLink, { [cls.active]: isActive }, [
          className,
          cls[theme],
        ])
      }
      to={to}
      {...otherProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={testId}
    >
      {({ isActive }) => getCorrectContent({ isActive })}
    </NavLink>
  );
});

export default AppLink;
