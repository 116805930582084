// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BodyTable_BodyTable__BChun {
  position: relative;
  display: grid;
  background: var(--White, #FFF);
}

.BodyTable_TableInfoLoader__XfbFj {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/BodyTable/ui/BodyTable.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,QAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;AACF","sourcesContent":[".BodyTable {\n  position: relative;\n  display: grid;\n  background: var(--White, #FFF);\n}\n\n.TableInfoLoader {\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BodyTable": `BodyTable_BodyTable__BChun`,
	"TableInfoLoader": `BodyTable_TableInfoLoader__XfbFj`
};
export default ___CSS_LOADER_EXPORT___;
