import { FC, memo } from "react";

import { useSelector } from "react-redux";
import { Loader } from "shared/ui/Loader";
import { Icon } from "shared/ui/Icon";

import { ReactComponent as IconNotFound } from "shared/assets/icons/not_found.svg";

import { selectTransactionsIsLoading } from "entities/Transactions/model/selectors/selectTransactionsIsLoading";

import cls from "./InfoTable.module.scss";
import { selectTransactions } from "entities/Transactions/model/selectors/selectTransactions";
import { selectTransactionsIsError } from "entities/Transactions/model/selectors/selectTransactionsIsError";
import { selectTransactionsIsSuccess } from "entities/Transactions/model/selectors/selectTransactionsIsSuccess";

interface ContentProps {
  countTransactions: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const Content = memo((props: ContentProps) => {
  const { isLoading, isError, isSuccess, countTransactions } = props;
  if (isLoading) {
    return (
      <div className={cls.wrapperLoader}>
        <Loader width={50} height={50} />
      </div>
    );
  }

  if (!isSuccess) {
    return null;
  }

  if (isError) {
    return (
      <div>
        <p>Возникли ошибки при загрузке</p>
      </div>
    );
  }

  if (countTransactions === 0) {
    return (
      <div className={cls.notFound}>
        <Icon
          Svg={IconNotFound}
          width={40}
          height={40}
          className={cls.iconNotFound}
        />
        <p className={cls.title}>Ничего не найдено</p>
        <p className={cls.subtitle}>
          Попробуйте изменить фильтры или поисковой запрос
        </p>
      </div>
    );
  }

  return null;
});

interface InfoTableProps {}

export const InfoTable: FC<InfoTableProps> = () => {
  const transactions = useSelector(selectTransactions);
  const isLoading = useSelector(selectTransactionsIsLoading);
  const isError = useSelector(selectTransactionsIsError);
  const isSuccess = useSelector(selectTransactionsIsSuccess);

  return (
    <div className={cls.InfoTable}>
      <Content
        countTransactions={transactions.length}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
      />
    </div>
  );
};
