import { AxiosInstance, AxiosResponse } from "axios";

import { IReqGetBalanceByPeriod, getBalanceByPeriod } from "./requests/getBalanceByPeriod";
import { getBalanceLastDays } from "./requests/getBalanceLastDays";

export class AnalyticsAPI {
  protected axios: AxiosInstance;

  public getBalanceLastDays: (req: void) => Promise<AxiosResponse<{ yesterday: number; today: number; }>>;
  public getBalanceByPeriod: (req: IReqGetBalanceByPeriod) => Promise<AxiosResponse<{ balance: number }>>;


  constructor(axios: AxiosInstance) {
    this.axios = axios;

    this.getBalanceLastDays = getBalanceLastDays.bind(this);
    this.getBalanceByPeriod = getBalanceByPeriod.bind(this);

  }
}
