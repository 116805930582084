import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "shared/lib/hooks/useAuth";
import { PendingPage } from "pages/PendingPage";

export const ProtectedRoute = () => {
  const { isValid, isLoading } = useAuth();

  if (isLoading) return <PendingPage />;

  return isValid ? <Outlet /> : <Navigate replace to="/sign-in" />;
};
