// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_InputWrapper__2bcHu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Input_input__sbWms {
  outline: none;
  background: none;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
  font: var(--font-l);
}
.Input_input__sbWms::placeholder {
  color: var(--text-black, #07232d);
  opacity: 0.5;
}

.Input_container__1yodZ {
  display: flex;
  align-items: center;
  position: relative;
}

.Input_fullWidth__dZHXE input {
  width: 100%;
}

.Input_error__\\+aKL2 {
  position: absolute;
  bottom: -50px;
  left: 0;
  opacity: 0;
}
.Input_error__\\+aKL2.Input_active__qoC3y {
  opacity: 1;
}

.Input_InputWrapper__2bcHu.Input_popup__fQFKz:not(:last-of-type) {
  margin-bottom: 24px;
}
.Input_InputWrapper__2bcHu.Input_popup__fQFKz label {
  color: var(--text-black, #07232d);
  font: var(--font-l);
  font-weight: 700;
  margin-bottom: 8px;
}
.Input_InputWrapper__2bcHu.Input_popup__fQFKz input {
  border-radius: 8px;
  background: var(--Grey, #eef1f4);
  padding: 14px 16px;
  font: var(--font-l);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Input/ui/Input.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,iCAAA;EACA,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAIE;EACE,WAAA;AADJ;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,OAAA;EACA,UAAA;AAFF;AAIE;EACE,UAAA;AAFJ;;AAQE;EACE,mBAAA;AALJ;AAQE;EACE,iCAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AANJ;AASE;EACE,kBAAA;EACA,gCAAA;EACA,kBAAA;EACA,mBAAA;AAPJ","sourcesContent":[".InputWrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.input {\n  outline: none;\n  background: none;\n  border: none;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  align-self: center;\n  font: var(--font-l);\n\n  &::placeholder {\n    color: var(--text-black, #07232d);\n    opacity: 0.5;\n  }\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.fullWidth {\n  input {\n    width: 100%;\n  }\n}\n\n.error {\n  position: absolute;\n  bottom: -50px;\n  left: 0;\n  opacity: 0;\n\n  &.active {\n    opacity: 1;\n  }\n}\n\n.InputWrapper.popup {\n\n  &:not(:last-of-type) {\n    margin-bottom: 24px;\n  }\n\n  label {\n    color: var(--text-black, #07232d);\n    font: var(--font-l);\n    font-weight: 700;\n    margin-bottom: 8px;\n  }\n\n  input {\n    border-radius: 8px;\n    background: var(--Grey, #eef1f4);\n    padding: 14px 16px;\n    font: var(--font-l);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputWrapper": `Input_InputWrapper__2bcHu`,
	"input": `Input_input__sbWms`,
	"container": `Input_container__1yodZ`,
	"fullWidth": `Input_fullWidth__dZHXE`,
	"error": `Input_error__+aKL2`,
	"active": `Input_active__qoC3y`,
	"popup": `Input_popup__fQFKz`
};
export default ___CSS_LOADER_EXPORT___;
