import { FC, useCallback, useState } from "react";
import classNames from "classnames";

import { CellTable } from "../CellTable/CellTable";
import { ITransaction } from "entities/Transactions/model/types/ITransaction";
import { IColumn } from "../../types/IColumn";
import { TableDataRules } from "../../TableDataRules";
import { ButtonShowTransactionInfo } from "../ButtonShowTransactionInfo/ButtonShowTransactionInfo";
import { TransactionInfo } from "../TransactionInfo/TransactionInfo";

import cls from "./RowTable.module.scss";

interface RowTableProps {
  className?: string;
  isHeader?: boolean;
  columns: IColumn[];
  row?: ITransaction;
}

const ContentRow = (props: {
  isHeader: boolean;
  row?: ITransaction;
  tableDataRules?: TableDataRules;
  columns: IColumn[];
}) => {
  const { isHeader, columns } = props;

  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);

  const handleToggleIsShowInfo = useCallback(() => {
    setIsShowInfo((prevValue) => !prevValue);
  }, []);

  if (isHeader) {
    return (
      <>
        {columns.map((column: IColumn, index) => (
          <CellTable
            key={index}
            column={column}
            style={column.style}
            {...props}
          />
        ))}
      </>
    );
  }

  const mods = {
    [cls.active]: isShowInfo,
  };

  return (
    <>
      <div
        className={classNames(cls.mainInfo, mods)}
        onClick={handleToggleIsShowInfo}
      >
        {columns.map((column: IColumn, index) => {
          if (column.type === "showTransactionInfo") {
            return (
              <ButtonShowTransactionInfo
                key={index}
                style={column.style}
                isShowInfo={isShowInfo}
                onToggle={handleToggleIsShowInfo}
              />
            );
          } else {
            return (
              <CellTable
                key={index}
                column={column}
                style={column.style}
                {...props}
              />
            );
          }
        })}
      </div>
      <div className={classNames(cls.additionalInfo, mods)}>
        <TransactionInfo isShowInfo={isShowInfo} {...props} />
      </div>
    </>
  );
};

export const RowTable: FC<RowTableProps> = (props) => {
  const { columns, row, isHeader = false } = props;

  const tableDataRules = row ? new TableDataRules(row) : undefined;

  const mods = {
    [cls.header]: isHeader,
  };

  return (
    <div className={classNames(cls.RowTable, mods)}>
      <ContentRow
        columns={columns}
        isHeader={isHeader}
        row={row}
        tableDataRules={tableDataRules}
      />
    </div>
  );
};

export default RowTable;
