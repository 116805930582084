import classNames from "classnames";
import { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

import { ITransaction } from "entities/Transactions/model/types/ITransaction";
import { TableDataRules } from "../../TableDataRules";
import { IColumn } from "../../types/IColumn";
import { CellTable } from "../CellTable/CellTable";

import cls from "./TransactionInfo.module.scss";

const listShowField = [
  // {
  //   key: "dropName",
  //   name: "ЛК",
  // },
  {
    key: "merchantId",
    name: "Мерчант",
  },
  // {
  //   key: "wallet",
  //   name: "Карта",
  // },
];

interface TransactionInfoProps {
  row?: ITransaction;
  columns: IColumn[];
  tableDataRules?: TableDataRules;
  isShowInfo: boolean;
}

export const TransactionInfo = ({
  row,
  columns,
  tableDataRules,
  isShowInfo,
}: TransactionInfoProps) => {
  if (!isShowInfo) return null;

  let indexColumn = columns.length - 2;

  const btnConfirm = (
    <div
      // style={columns[columns.length - 1].style}
      style={{ flexBasis: "15%" }}
      className={cls.wrapperBtnConfirm}
      key={uuidv4()}
    ></div>
  );
  //btnConfirm
  const container: ReactNode[] = [];

  if (row) {
    listShowField.forEach(({ key, name }, index) => {
      const columnStyle = columns[indexColumn].style;
      if (Object.keys(row).includes(key)) {
        container.push(
          <div
            className={cls.field}
            key={index}
            style={{
              flexBasis: "15%",
              // width: columnStyle ? columnStyle.width : "auto",
            }}
          >
            <CellTable isHeader={true} column={columns[indexColumn]}>
              <p className={cls.title}> {name}</p>
            </CellTable>
            <CellTable isHeader={false} column={columns[indexColumn]}>
              <p className={cls.value}>{tableDataRules?.getCorrectData(key)}</p>
            </CellTable>
          </div>
        );
        indexColumn--;
      }
    });
  }

  const mods = {
    [cls.show]: isShowInfo,
  };

  return (
    <div className={classNames(cls.TransactionInfo, mods)}>{container}</div>
  );
};
