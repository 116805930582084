import { FC, memo } from "react";

import { NavBar } from "widgets/NavBar";
import { Icon } from "shared/ui/Icon";
import { ReactComponent as IconLogo } from "shared/assets/icons/logo.svg";

import cls from "./SideBar.module.scss";
import { ButtonExit } from "features/logOut";

interface SideBarProps {}

const SideBar: FC<SideBarProps> = memo(() => {
  return (
    <div className={cls.SideBar}>
      <div className={cls.content}>
        <div className={cls.top}>
          <Icon Svg={IconLogo} width={72} height={72} className={cls.logo} />
        </div>
        <div className={cls.main}>
          <NavBar />
        </div>
        <div className={cls.bottom}>
          <ButtonExit />
        </div>
      </div>
    </div>
  );
});

export default SideBar;
