// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PendingPage_PendingPage__8QiwJ {
  height: 100vh;
  width: 100vw;
  background: var(--Background, #f7f8f9);
  display: grid;
  place-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/PendingPage/ui/PendingPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,sCAAA;EACA,aAAA;EACA,mBAAA;AACF","sourcesContent":[".PendingPage {\n  height: 100vh;\n  width: 100vw;\n  background: var(--Background, #f7f8f9);\n  display: grid;\n  place-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PendingPage": `PendingPage_PendingPage__8QiwJ`
};
export default ___CSS_LOADER_EXPORT___;
