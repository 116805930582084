import { AxiosResponse } from "axios";

import { UserAPI } from "..";
import { URL_CHECK_AUTH } from "shared/config/apiConfig/apiConfig";

export interface IReqCheckAuth {
  authdata: string;
}

export function checkAuth(
  this: UserAPI,
  req: IReqCheckAuth
): Promise<AxiosResponse> {
  const { authdata } = req;
  return this.axios.get(URL_CHECK_AUTH, {
    headers: {
      Authorization: `Basic ${authdata}`,
    },
  });
}
