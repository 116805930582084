import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "app/providers/StoreProvider";

import { API } from "shared/api";
import { IStatus } from "shared/types/IStatus";

export const getStatuses = createAsyncThunk<
  IStatus[],
  void,
  ThunkConfig<string>
>("transactionsFilter/getStatuses", async (_, thunkApi) => {
  const { rejectWithValue } = thunkApi;

  try {
    const res = await API.settings.getStatuses({ isCache: true });
    return res.data;
  } catch (error) {
    return rejectWithValue("Не удалось получить cтатусы");
  }
});
