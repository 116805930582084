import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { InfoTable } from "./components/InfoTable/InfoTable";
import { BodyTable } from "./components/BodyTable";
import { HeaderTable } from "./components/HeaderTable";
import { PaginationTable } from "./components/PaginationTable/PaginationTable";

import { columns } from "./conts/columns";

import { useAppDispatch } from "shared/lib/hooks/useAppDispatch";
import { selectIsSuccessFilterData } from "entities/Transactions/model/slices/transactionsFilterReducer";
import { selectTransactionsPage } from "../../model/selectors/selectTransactionsPage";
import { getTransactions } from "../../model/services/getTransactions";

import cls from "./TransactionTable.module.scss";

export const TransactionTable: FC = () => {
  const dispatch = useAppDispatch();

  const isSucessStatuses = useSelector(selectIsSuccessFilterData("statuses"));

  const page = useSelector(selectTransactionsPage);

  useEffect(() => {
    if (isSucessStatuses) {
      dispatch(
        getTransactions({
          params: {
            size: 10,
          },
          isCache: true,
        })
      );
    }
  }, [dispatch, isSucessStatuses, page]);

  return (
    <div className={cls.TransactionTable}>
      <HeaderTable columns={columns} />
      <BodyTable columns={columns} />
      <PaginationTable />
      <InfoTable />
    </div>
  );
};
