import { AxiosInstance, AxiosResponse } from "axios";
import { IReqGetStatuses, getStatuses } from "./requests/getStatuses";

export class SettingsAPI {
  protected axios: AxiosInstance;

  public getStatuses: (req: IReqGetStatuses) => Promise<AxiosResponse>;

  constructor(axios: AxiosInstance) {
    this.axios = axios;

    this.getStatuses = getStatuses.bind(this);
  }
}
