// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK {
  display: flex;
  align-items: center;
}
.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK p {
  margin-right: 4px;
}
.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK:hover {
  color: #03bafc;
}
.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK:hover .ButtonShowTransactionInfo_iconDownArrow__OWemo * {
  fill: #03bafc;
}
.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK:active {
  color: #1587b0;
}
.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK:active .ButtonShowTransactionInfo_iconDownArrow__OWemo * {
  fill: #1587b0;
}

.ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/ButtonShowTransactionInfo/ButtonShowTransactionInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AACI;EACE,aAAA;AACN;AAGE;EACE,cAAA;AADJ;AAEI;EACE,aAAA;AAAN;;AAKA;EACE,kBAAA;AAFF","sourcesContent":[".btnShowTransactionInfo {\n  display: flex;\n  align-items: center;\n\n  p {\n    margin-right: 4px;\n  }\n\n  &:hover {\n    color: #03bafc;\n    .iconDownArrow * {\n      fill: #03bafc;\n    }\n  }\n\n  &:active {\n    color: #1587b0;\n    .iconDownArrow * {\n      fill: #1587b0;\n    }\n  }\n}\n\n.btnShowTransactionInfo {\n  margin-right: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnShowTransactionInfo": `ButtonShowTransactionInfo_btnShowTransactionInfo__rzKOK`,
	"iconDownArrow": `ButtonShowTransactionInfo_iconDownArrow__OWemo`
};
export default ___CSS_LOADER_EXPORT___;
