import axios, { AxiosInstance } from "axios";

import { SettingsAPI } from "./settings";
import { TransactionsAPI } from "./transactions";
import { UserAPI } from "./user";
import { AnalyticsAPI } from "./analytics";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "/",
  timeout: 5000,
});

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

export class API {
  static transactions = new TransactionsAPI(axiosInstance);
  static settings = new SettingsAPI(axiosInstance);
  static user = new UserAPI(axiosInstance);
  static analytics = new AnalyticsAPI(axiosInstance);
}
