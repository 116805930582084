import { createSlice } from "@reduxjs/toolkit";
import { StateSchema } from "app/providers/StoreProvider";
import { PopupSchema } from "./types/PopupSchema";

export enum TypePopups {
  UPLOAD_TRANSACTIONS = "upload_transactions",
  BLOCK_TRADER_CARD = "block_trader_card",
  CREATE_TRADER = "create_trader",
  CREATE_PERSONAL_ACCOUNT = "create_trader_account",
  CREATE_TRADER_WALLET = "create_trader_wallet",
  DELETE_TRADER_WALLET = "delete_trader_wallet"
}

const initialState: PopupSchema = {
  data: {},
  isOpen: false,
  typePopup: "",
};

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    open: (state, action) => {
      const { data = {}, typePopup = "" } = action.payload;

      state.data = data;
      state.isOpen = true;
      state.typePopup = typePopup;
    },
    clearData: (state) => {
      state.data = {};
    },
    close: (state) => ({
      ...initialState,
      typePopup: state.typePopup,
    }),
  },
});

export const selectIsOpenPopup = (state: StateSchema) => state.popup.isOpen;
export const selectTypePopup = (state: StateSchema) => state.popup.typePopup;
export const selectDataPopup = (state: StateSchema) => state.popup.data;

export const { actions: popupActions, reducer: popupReducer } = popupSlice;
