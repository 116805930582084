// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_Navbar__Hbi-2 {
  width: 100%;
  display: flex;
}

.NavBar_links__cBMge {
  display: grid;
  grid-template-rows: auto;
  row-gap: 35px;
}

@media screen and (max-width: 1600px) {
  .NavBar_links__cBMge {
    row-gap: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/NavBar/ui/NavBar/NavBar.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,wBAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".Navbar {\n  width: 100%;\n  display: flex;\n}\n\n.links {\n  display: grid;\n  grid-template-rows: auto;\n  row-gap: 35px;\n}\n\n@media screen and (max-width: 1600px){\n  .links  {\n    row-gap: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navbar": `NavBar_Navbar__Hbi-2`,
	"links": `NavBar_links__cBMge`
};
export default ___CSS_LOADER_EXPORT___;
