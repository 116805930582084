import { AxiosInstance, AxiosResponse } from "axios";

import {
  IReqGetTransactions,
  IResGetTransactions,
  getTransactions,
} from "./requests/getTransactions";

import { IReqGetExcel, getExcel } from "./requests/getExcel";

export class TransactionsAPI {
  protected axios: AxiosInstance;

  public getTransactions: (
    req: IReqGetTransactions
  ) => Promise<AxiosResponse<IResGetTransactions>>;

  public getExcel: (req: IReqGetExcel) => Promise<AxiosResponse>;

  constructor(axios: AxiosInstance) {
    this.axios = axios;

    this.getTransactions = getTransactions;
    this.getExcel = getExcel.bind(this);
  }
}
