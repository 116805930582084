import { createAsyncThunk } from "@reduxjs/toolkit";
import { StateSchema, ThunkConfig } from "app/providers/StoreProvider";
import { transactionActions } from "../slices/transactionsReducer";
import { API } from "shared/api";
import {
  IReqGetTransactions,
  ParamsKeysReqGetTransactions,
} from "shared/api/transactions/requests/getTransactions";
import { ITransaction } from "../types/ITransaction";

const isValidCach = (
  state: StateSchema,
  params: {
    page: number;
    size: number;
    query: string;
    from: number;
    to: number;
    statuses: string;
  }
) => {
  const indexCacheData = Object.keys(state.transactions.cachedData).findIndex(
    (pageCached) => parseInt(pageCached) === params.page
  );

  if (indexCacheData === -1) return false;

  const cacheData = state.transactions.cachedData[params.page];

  if (cacheData.from !== params.from) return false;
  if (cacheData.to !== params.to) return false;
  if (cacheData.statuses !== params.statuses) return false;
  if (cacheData.query !== params.query) return false;

  return true;
};

const getCorrectPage = (state: StateSchema, page?: number) => {
  if (page === 0) return 0;
  if (Number.isFinite(page)) {
    return page;
  }
  return state.transactions.page;
};

interface ISendReq {
  params: {
    page?: number;
    size?: number;
    query?: string;
    from?: number;
    to?: number;
  };
  isCache?: boolean;
}

export const getTransactions = createAsyncThunk(
  "transactions/getTransactions",
  async ({ params, isCache }: ISendReq, thunkAPI: any) => {
    const { page, size } = params;
    const { getState, dispatch, rejectWithValue } = thunkAPI;
    const state = getState() as StateSchema;

    const listParams: Array<[ParamsKeysReqGetTransactions, string | number]> =
      [];

    const correctPage = getCorrectPage(state, page);
    const correctSize = size
      ? Number.isFinite(size)
        ? size
        : 10
      : state.transactions.pageSize;

    const query = state.transactionsFilter.query;
    // Период
    const selectesPeriodId = state.transactionsFilter.selectedPeriod.id;
    const from = state.transactionsFilter.calendar.startDate;
    const to = state.transactionsFilter.calendar.endDate;
    // Статусы
    const statuses = state.transactionsFilter.selectedStatuses
      .filter((statuse) => statuse.id !== -1)
      .map((statuse) => statuse.name)
      .join(",");

    if (query !== "") listParams.push(["query", query]);

    if (selectesPeriodId !== 1) {
      listParams.push(["from", from]);
      listParams.push(["to", to]);
    }
    listParams.push(["page", correctPage || 0]);
    listParams.push(["size", correctSize || 10]);
    listParams.push(["statuses", statuses]);

    const sendParams = Object.fromEntries(
      listParams
    ) as IReqGetTransactions["params"];

    // if (isCache) {
    //   const isDataCached = isValidCach(state, {
    //     page: correctPage || 0,
    //     size: correctSize,
    //     query,
    //     from,
    //     to,
    //     statuses,
    //   });
    // }

    try {
      const res = await API.transactions.getTransactions({
        params: sendParams,
      });
      const data = res.data;
      dispatch(
        transactionActions.setCachedData({
          from,
          to,
          statuses,
          query,
          ...data,
        })
      );
      return data;
    } catch (error) {
      return rejectWithValue("Не удалось получить таблицу");
    }
  }
);
