import { AxiosResponse } from "axios";

import { URL_GET_BALANCE_BY_PERIOD } from "shared/config/apiConfig/apiConfig";
import { AnalyticsAPI } from "..";

export interface IReqGetBalanceByPeriod {
  params: {
    from: string;
    to: string;
  };
}

export function getBalanceByPeriod(
  this: AnalyticsAPI,
  req: IReqGetBalanceByPeriod
): Promise<AxiosResponse> {
  const authdata = localStorage.getItem("authdata");
  return this.axios.get(URL_GET_BALANCE_BY_PERIOD, {
    headers: {
      Authorization: `Basic ${authdata}`,
    },
    params: req.params,
  });
}
