import classNames from "classnames";

import { Button } from "shared/ui/Button";
import { Icon } from "shared/ui/Icon";
import { ReactComponent as IconDownArrow } from "shared/assets/icons/arrow_down_2.svg";

import cls from "./ButtonShowTransactionInfo.module.scss";
import { CSSProperties } from "react";

interface ButtonShowTransactionInfoProps {
  style?: CSSProperties;
  isShowInfo: boolean;
  onToggle: () => void;
}

export const ButtonShowTransactionInfo = ({
  style,
  isShowInfo,
  onToggle,
}: ButtonShowTransactionInfoProps) => {
  return (
    <Button
      onClick={onToggle}
      className={cls.btnShowTransactionInfo}
      style={style}
    >
      <Icon
        Svg={IconDownArrow}
        width={35}
        height={35}
        className={classNames(cls.iconDownArrow, {
          [cls.active]: isShowInfo,
        })}
      />
    </Button>
  );
};

