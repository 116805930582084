import { FC } from "react";
import classNames from "classnames";

import { AppLink, AppLinkTheme } from "shared/ui/AppLink";
import { NabBarItemType } from "../../model/item";

import cls from "./NabBarItem.module.scss";

interface NabBarItemProps {
  className?: string
  item?: NabBarItemType;
}

export const NabBarItem: FC<NabBarItemProps> = (props) => {
  const { className, item } = props;

  return (
    <li className={classNames(cls.link, [className])}>
      <AppLink
        theme={AppLinkTheme.SECONDARY}
        to={item?.path || ""}
        icon={item?.Icon}
        iconActive={item?.IconActive}
        testId={item?.testId}
      >
        {item?.text}
      </AppLink>
    </li>
  );
};

