import { useEffect, useMemo, useState } from "react";
import { API } from "shared/api";

export const useAuth = () => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authdata = localStorage.getItem("authdata");
    if (authdata) {
      API.user
        .checkAuth({ authdata })
        .then(() => {
          setValid(true);
        })
        .catch(() => {
          setValid(false);
          localStorage.removeItem("authdata");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return useMemo(() => {
    return {
      isValid: valid,
      isLoading: loading,
    };
  }, [loading, valid]);
};
