import { CSSProperties, FC } from "react";

import classNames from "classnames";

import cls from "./Loader.module.scss";

interface LoaderProps {
  className?: string;
  width?: number;
  height?: number;
  borderColor?: string;
  borderBottomColor?: string;
  borderWidth?: string;
}

const Loader: FC<LoaderProps> = (props) => {
  const {
    className,
    width = 34,
    height = 34,
    borderColor,
    borderBottomColor,
    borderWidth,
  } = props;

  return (
    <span
      className={classNames(cls.Loader, [className])}
      style={{
        width,
        height,
        borderColor,
        borderBottomColor,
        borderWidth,
      }}
    ></span>
  );
};

export default Loader;
