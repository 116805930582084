import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import moment from "moment";

import { StateSchema } from "app/providers/StoreProvider";
import { API } from "shared/api";
import { ParamsKeysReqGetExcel } from "shared/api/transactions/requests/getExcel";

const getDateRange = (daysAgo = 0) => {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);

  const startDate = new Date(date);
  startDate.setHours(0, 0, 0);

  const endDate = new Date(date);
  endDate.setHours(23, 59, 59);

  return {
    startDate: startDate.getTime(),
    endDate: endDate.getTime(),
  };
};

const getFormatDate = (date: number, type: "to" | "from") => {
  const formattedDate = moment(new Date(date));
  if (type === "to") {
    formattedDate.set({ hour: 23, minute: 59, second: 59 });
  }
  return formattedDate.format();
};

export const getTransactionsExcel = createAsyncThunk(
  "transactions/getTransactionsExcel",
  async ({ email }: { email: string }, ThunkAPI) => {
    const state = ThunkAPI.getState() as StateSchema;

    const listParams: Array<
      [ParamsKeysReqGetExcel, string | number | boolean | Date]
    > = [];

    // Поиск
    const query = state.transactionsFilter.query;
    // Период
    const selectesPeriodId = state.transactionsFilter.selectedPeriod.id;
    const from = getFormatDate(state.transactionsFilter.calendar.startDate, "from");
    const to = getFormatDate(state.transactionsFilter.calendar.endDate, "to");
    // Статусы
    const statuses: string = state.transactionsFilter.selectedStatuses
      .filter((statuse) => statuse.id !== -1)
      .map((statuse) => statuse.name)
      .join(",");

    if (selectesPeriodId !== 1) {
      listParams.push(["from", from]);
      listParams.push(["to", to]);
    } else {
      listParams.push(["from", getFormatDate(0, "from")]);
      listParams.push(["to", getFormatDate(getDateRange().endDate, "to")]);
    }

    listParams.push(["query", query]);
    listParams.push(["statuses", statuses]);
    listParams.push(["email", email]);

    const sendParams = Object.fromEntries(listParams) as any;

    try {
      await API.transactions.getExcel({
        params: sendParams,
      });
      toast.success("Таблица успешно отправлена на указанную почту");
    } catch (error) {
      toast.error("Таблицу не удалось выгрузить на указанную почту");
      return ThunkAPI.rejectWithValue("Не удалось выгрузить таблицу");
    }
  }
);
