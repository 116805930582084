const BASE_URL = process.env.REACT_APP_API_URL;

// transactions
export const URL_GET_TRANSACTIONS = `${BASE_URL}/transactions/get`;
export const URL_GET_EXCEL = `${BASE_URL}/transactions/excel`;

// settings
export const URL_GET_STATUSES = `${BASE_URL}/utils/statuses`;

// auth
export const URL_CHECK_AUTH = `${BASE_URL}/auth`;

// analytics
export const URL_GET_BALANCE_BY_PERIOD = `${BASE_URL}/analytics/balance`;
export const URL_GET_BALANCE_LAST_DAYS = `${BASE_URL}/analytics/balance/recent`;


