import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "shared/api";
import { StateSchema } from "app/providers/StoreProvider";
import { UserSchema } from "../types/UserSchema";

export interface ILoginReq {
  name: string;
  password: string;
}

const initialState: UserSchema = {
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const login = createAsyncThunk(
  "user/login",
  async (body: ILoginReq, thunkAPI) => {
    const { name, password } = body;
    const authdata = btoa(`${name}:${password}`);
    try {
      await API.user.checkAuth({ authdata });
      localStorage.setItem("authdata", authdata);
      toast.success("Добро пожаловать");
      return name;
    } catch (error) {
      toast.error("Авторзация не пройдена");
      return thunkAPI.rejectWithValue("Авторзация не пройдена");
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signOut: () => {
      localStorage.clear();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
    });
  },
});

export const selectIsLoading = (state: StateSchema) => state.user.isLoading;
export const selectIsError = (state: StateSchema) => state.user.isError;
export const selectIsSuccess = (state: StateSchema) => state.user.isSuccess;

export const { signOut } = userSlice.actions;
export const { reducer: userReducer } = userSlice;