import { AxiosResponse } from "axios";
import { URL_GET_TRANSACTIONS } from "shared/config/apiConfig/apiConfig";
import { TransactionsAPI } from "..";
import { ITransaction } from "entities/Transactions/model/types/ITransaction";

export interface IReqGetTransactions {
  params: {
    page: number;
    size: number;
    from?: number;
    to?: number;
    query?: string;
    statuses?: string;
  };
}

export interface IResGetTransactions {
  data: ITransaction[];
  page: number;
  pageSize: number;
  pageCount: number;
}

export type ParamsKeysReqGetTransactions = keyof IReqGetTransactions["params"];

export function getTransactions(
  this: TransactionsAPI,
  req: IReqGetTransactions
): Promise<AxiosResponse<IResGetTransactions>> {
  const { params } = req;
  const authdata = localStorage.getItem("authdata");
  return this.axios.get(URL_GET_TRANSACTIONS, {
    params,
    headers: {
      Authorization: `Basic ${authdata}`,
    },
  });
}
