// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionTable_TransactionTable__Jzhq6 {
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
  max-width: 1400px;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/TransactionTable.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;AACF","sourcesContent":[".TransactionTable {\n  margin-top: 20px;\n  align-self: flex-start;\n  width: 100%;\n  max-width: 1400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TransactionTable": `TransactionTable_TransactionTable__Jzhq6`
};
export default ___CSS_LOADER_EXPORT___;
