import { FC } from "react";

import { RowTable } from "../../RowTable/RowTable";
import { IColumn } from "../../../types/IColumn";

import cls from "./HeaderTable.module.scss";

interface HeaderTableProps {
  columns: IColumn[];
}

const HeaderTable: FC<HeaderTableProps> = (props) => {
  const { columns } = props;

  return (
    <div className={cls.HeaderTable}>
      <RowTable columns={columns} isHeader/>
    </div>
  );
};

export default HeaderTable;
