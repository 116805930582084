import { FC, ReactNode } from "react";
import { Provider } from "react-redux";
import { createReduxStore } from "../config/store";
import { DeepPartial } from "@reduxjs/toolkit";
import { StateSchema } from "../config/StateSchema";
import { useNavigate } from "react-router-dom";

interface StoreProviderProps {
  children?: ReactNode;
  initialState?: DeepPartial<StateSchema>;
}

const StoreProvider: FC<StoreProviderProps> = (props) => {
  const { children, initialState } = props;

  const navigate = useNavigate();

  const store = createReduxStore(initialState as StateSchema, navigate);

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
