import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "shared/api";
import { ITransaction } from "../types/ITransaction";
import { toast } from "react-toastify";
import { getTransactions } from "../services/getTransactions";
import { IResGetTransactions } from "shared/api/transactions/requests/getTransactions";

interface ICachedData {
  [page: string]: any;
}

export interface TransactionsState {
  transactions: ITransaction[];
  page: number;
  pageCount: number;
  pageSize: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  cachedData: ICachedData;
}

const initialState: TransactionsState = {
  transactions: [],
  page: 0,
  pageCount: 0,
  pageSize: 10,
  isLoading: false,
  isError: false,
  isSuccess: false,
  cachedData: {},
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCachedData: (state, action) => {
      const { page } = action.payload;
      state.cachedData[page] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.transactions = [];
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      const { data, page, pageCount, pageSize } = action.payload;

      state.isLoading = false;
      state.isSuccess = true;

      state.transactions = data;

      state.page = page;
      state.pageCount = pageCount;
      state.pageSize = pageSize;
    });

    builder.addCase(getTransactions.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.transactions = [];
    });
  },
});

export const { actions: transactionActions } = transactionsSlice;

export default transactionsSlice.reducer;
