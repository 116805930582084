import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "shared/api";

export const getBalanceLastDays = createAsyncThunk(
  "analytics/getBalanceLastDays",
  async (_, thunkAPI) => {
    try {
      const res = await API.analytics.getBalanceLastDays();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);