import { AxiosResponse } from "axios";

import { URL_GET_BALANCE_LAST_DAYS } from "shared/config/apiConfig/apiConfig";
import { AnalyticsAPI } from "..";

export function getBalanceLastDays(this: AnalyticsAPI): Promise<AxiosResponse> {
  const authdata = localStorage.getItem("authdata");
  return this.axios.get(URL_GET_BALANCE_LAST_DAYS, {
    headers: {
      Authorization: `Basic ${authdata}`,
    },
  });
}
