export enum ListShowColumns {
  TRANSACTION_ID = "transactionId",
  EXTERNAL_ID = "externalId",
  TRADER_ID = "traderId",
  MERCHANT_ID = "merchantId",
  AMOUNT = "amount",
  PAID_AMOUNT = "paidAmount",
  WALLET_PROVIDER = "walletProvider",
  PAYMENT_UNIT = "paymentUnit",
  PAYER_WALLET = "payerWallet",
  ADDITIONAL_DATA = "additionalData",
  CREATED_DATE = "createDate",
  DONE_DATE = "doneDate",
  STATUS = "status",
  DONE = "done",
  ERROR = "error",
  DEVICE_ID = "deviceId",
  TRADER_TG = "traderTg",
  TRANSACTION_ISSUED = "transactionIssued",
  COMISSION = "commission",
  ISSUED_AMOUNT = "issuedAmount",
  WALLET = "wallet",
  WALLET_ID = "walletId",
  DEVICE_NAME = "deviceName",
  AMOUNT_USDT = "amountUsdt",
  USDT_RATE = "usdtRate",
  APPROVED = "approved",
  TRADER_TG_ID = "traderTgId",
  DROP_NAME = "dropName",
  DRROP_ID = "dropId",
  POSTBACK_TOKEN = "postbackToken",
  POSTBACK_SEND = "postbackSent",
  PENDING = "pending"
}

export const enum ListStatuses {
  CREATED = "Created",
  FULLY_PAID = "Fully paid",
  PARTIALLY_PAID = "Partially paid",
  FAIL = "Fail",
  EXPIRED = "Expired",
  WAITING = "Waiting",
  OVERPRICED = "Overpriced",
  NOT_FOUND = "Not found",
  NO_CARD = "No card",
  CANCELED = "Canceled",
  RE_BIND = "Re bind",
  TEST = "Test",
}

export const bgColorStatuses = new Map([
  ["CREATED", "#D4B5FB"],
  ["FULLY_PAID", "#C2EC7D"],
  ["PARTIALLY_PAID", "#9ADFFC"],
  ["FAIL", "#E55656"],
  ["EXPIRED", "#F5BB76"],
  ["WAITING", "#FCE465"],
  ["OVERPRICED", "#FEBCFF"],
  ["NOT_FOUND", "#FF99CA"],
  ["NO_CARD", "#FF9878"],
  ["CANCELED", "green"],
  ["RE_BIND", "green"],
  ["TEST", "green"],
]);
