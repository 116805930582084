import { ButtonHTMLAttributes, FC, memo } from "react";

import classNames from "classnames";
import cls from "./Button.module.scss";

export enum ThemeButton {
  CLEAR = "clear",
  RED = "redBackground",
  RED_OUTLINE = "redOutline",
  LIGHT_BLUE = "lightBlue",
  LIGHT_BLUE_OUTLINE = "lightBlueOutline"
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: ThemeButton;
  fullWidth?: boolean;
}

const Button: FC<ButtonProps> = memo((props) => {
  const {
    className,
    children,
    theme = ThemeButton.CLEAR,
    fullWidth,
    ...otherProps
  } = props;

  const mods = {
    [cls[theme]]: true,
    [cls.fullWidth]: fullWidth,
  }

  return (
    <button
      type="button"
      className={classNames(cls.Button, mods, [className])}
      {...otherProps}
    >
      {children}
    </button>
  );
});

export default Button;
