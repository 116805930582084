import { FC, memo } from "react";

import { NabBarItemsList } from "widgets/NavBar/model/item";
import { NabBarItem } from "../NabBarItem/NabBarItem";

import cls from "./NavBar.module.scss";

interface NavBarProps {}

const NavBar: FC<NavBarProps> = memo(() => {
  return (
    <div className={cls.Navbar}>
      <nav>
        <ul className={cls.links}>
          {NabBarItemsList.map((item) => (
            <NabBarItem key={item.path} item={item} />
          ))}
        </ul>
      </nav>
    </div>
  );
});

export default NavBar;
