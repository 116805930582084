import { CSSProperties, FC, PropsWithChildren } from "react";
import classNames from "classnames";

import { IColumn } from "../../types/IColumn";
import { ITransaction } from "entities/Transactions/model/types/ITransaction";
import { ListShowColumns, bgColorStatuses } from "entities/Transactions/model/types/table";
import { TableDataRules } from "../../TableDataRules";

import cls from "./CellTable.module.scss";

interface ContentCellTableProps extends CellTableProps {}

const ContentCellTable = ({ column, ...props }: ContentCellTableProps) => {
  const { type, field, header } = column;
  const { row, tableDataRules, isHeader, children } = props;

  const keyColumn = field as ListShowColumns;

  if (children) {
    return <>{children}</>;
  }

  if (isHeader) {
    return <p>{header}</p>;
  }

  switch (type) {
    case "showTransactionInfo":
      return null;
    case "statusTransaction":
      return (
        <div
          style={{
            backgroundColor: row ? bgColorStatuses.get(row.status) : "",
            padding: 10,
            borderRadius: 8,
          }}
        >
          {row?.status}
        </div>
      );
    default:
      return <p>{tableDataRules?.getCorrectData(keyColumn)}</p>;
  }
};

interface CellTableProps extends PropsWithChildren {
  isHeader: boolean;
  column: IColumn;
  isShowInfo?: boolean;
  onToggleIsShowInfo?: () => void;
  row?: ITransaction;
  tableDataRules?: TableDataRules;
  style?: CSSProperties;
}

export const CellTable: FC<CellTableProps> = (props) => {
  const { isHeader, style } = props;

  const mods = {
    [cls.header]: isHeader,
  };

  return (
    <div className={classNames(cls.CellTable, mods)} style={style}>
      <ContentCellTable {...props} />
    </div>
  );
};
