import { ListShowColumns } from "entities/Transactions/model/types/table";
import { IColumn } from "../types/IColumn";

export const columns: IColumn[] = [
  {
    header: "ID",
    field: ListShowColumns.TRANSACTION_ID,
    style: { width: "35%" },
  },
  {
    header: "Дата создания",
    field: ListShowColumns.CREATED_DATE,
    style: { width: "15%", justifyContent: "flex-end" },
  },
  {
    header: "Сумма",
    field: ListShowColumns.AMOUNT,
    style: { width: "14%", justifyContent: "flex-end" },
  },
  {
    header: "Выплачено",
    field: ListShowColumns.PAID_AMOUNT,
    style: { width: "16%", justifyContent: "flex-end" },
  },
  // {
  //   header: "Трейдер",
  //   field: ListShowColumns.TRADER_TG,
  //   style: { width: "18%", justifyContent: "flex-end" },
  // },
  {
    header: "Статус",
    field: ListShowColumns.STATUS,
    type: "statusTransaction",
    style: { width: "14%", justifyContent: "flex-end" },
  },

  // {
  //   header: "Подробнее",
  //   field: ListShowColumns.STATUS,
  //   type: "showTransactionInfo",
  //   style: { width: "20%", justifyContent: "flex-end" },
  // },
];
