// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoTable_InfoTable__2DcZo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoTable_notFound__RL0kH {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.InfoTable_iconNotFound__7eXib {
  margin-bottom: 16px;
}

.InfoTable_title__d5muM {
  color: #014963;
  font: var(--font-l);
  font-weight: 500;
  margin-bottom: 8px;
}

.InfoTable_subtitle__CJQwk {
  color: #014963;
  font: var(--font-s);
  font-weight: 400;
}

.InfoTable_wrapperLoader__zEY93 {
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/InfoTable/InfoTable.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".InfoTable {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.notFound {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 50px;\n}\n\n.iconNotFound {\n  margin-bottom: 16px;\n}\n\n.title {\n  color: #014963;\n  font: var(--font-l);\n  font-weight: 500;\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  color: #014963;\n  font: var(--font-s);\n  font-weight: 400;\n}\n\n.wrapperLoader {\n  margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InfoTable": `InfoTable_InfoTable__2DcZo`,
	"notFound": `InfoTable_notFound__RL0kH`,
	"iconNotFound": `InfoTable_iconNotFound__7eXib`,
	"title": `InfoTable_title__d5muM`,
	"subtitle": `InfoTable_subtitle__CJQwk`,
	"wrapperLoader": `InfoTable_wrapperLoader__zEY93`
};
export default ___CSS_LOADER_EXPORT___;
