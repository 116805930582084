import { FC } from "react";

import { RowTable } from "../../RowTable/RowTable";
import { ITransaction } from "../../../../../model/types/ITransaction";
import { IColumn } from "../../../types/IColumn";

import { useSelector } from "react-redux";
import { selectTransactions } from "entities/Transactions/model/selectors/selectTransactions";
import { selectTransactionsIsSuccess } from "entities/Transactions/model/selectors/selectTransactionsIsSuccess";

import cls from "./BodyTable.module.scss";

interface BodyTableProps {
  columns: IColumn[];
}

const BodyTable: FC<BodyTableProps> = (props) => {
  const { columns } = props;

  const transactions = useSelector(selectTransactions);
  const isSuccess = useSelector(selectTransactionsIsSuccess);

  if (!isSuccess) return null;

  return (
    <div className={cls.BodyTable}>
      {transactions.map((row: ITransaction) => (
        <RowTable key={row?.transactionId} row={row} columns={columns} />
      ))}
    </div>
  );
};

export default BodyTable;
