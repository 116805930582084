import { ReducersMapObject, configureStore } from "@reduxjs/toolkit";
import { NavigateOptions, To } from "react-router-dom";

import transactionsFilterReducer from "entities/Transactions/model/slices/transactionsFilterReducer";
import transactionsReducer from "entities/Transactions/model/slices/transactionsReducer";
import { userReducer } from "entities/User1/slices/userReducer";

import { popupReducer } from "shared/serviceSlices/popup/popupReducer";
import { StateSchema, ThunkExtraArg } from "./StateSchema";
import { analyticsReducer } from "pages/AnalyticsPage";

export function createReduxStore(
  initialState?: StateSchema,
  navigate?: (to: To, options?: NavigateOptions) => void
) {
  const rootReducer: ReducersMapObject<StateSchema> = {
    transactions: transactionsReducer,
    transactionsFilter: transactionsFilterReducer,
    popup: popupReducer,
    user: userReducer,
    analytics: analyticsReducer
  };

  const extraArg: ThunkExtraArg = {
    navigate,
  };

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: extraArg,
        },
      }),
  });

  return store;
}

export type RootState = ReturnType<typeof createReduxStore>;
export type AppDispatch = RootState["dispatch"];
