import { AxiosResponse } from "axios";
import { TransactionsAPI } from "..";
import { URL_GET_EXCEL } from "shared/config/apiConfig/apiConfig";

export interface IReqGetExcel {
  params: {
    from: number;
    to: number;
    query: string;
    statuses: string;
    email: string;
    bot: boolean;
  };
}

export type ParamsKeysReqGetExcel = keyof IReqGetExcel["params"];

export function getExcel(
  this: TransactionsAPI,
  req: IReqGetExcel
): Promise<AxiosResponse> {
  const { params } = req;
  const authdata = localStorage.getItem("authdata");
  return this.axios.get(URL_GET_EXCEL, {
    params,
    headers: {
      Authorization: `Basic ${authdata}`,
    },
  });
}
