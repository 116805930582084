// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CellTable_CellTable__ktsGn {
  font: var(--font-l);
  font-weight: 400;
  color: var(--text-black, #07232d);
  display: flex;
  align-items: center;
}
.CellTable_CellTable__ktsGn .CellTable_text__7nNvI {
  overflow: hidden;
  text-overflow: ellipsis;
}
.CellTable_CellTable__ktsGn.CellTable_header__gD5Iy {
  color: var(--text-black, #07232d);
  font: var(--font-m);
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/CellTable/CellTable.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gBAAA;EACA,iCAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,uBAAA;AACJ;AAEE;EACE,iCAAA;EACA,mBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".CellTable {\n  font: var(--font-l);\n  font-weight: 400;\n  color: var(--text-black, #07232d);\n  display: flex;\n  align-items: center;\n\n  .text {\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  &.header {\n    color: var(--text-black, #07232d);\n    font: var(--font-m);\n    font-weight: 700;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CellTable": `CellTable_CellTable__ktsGn`,
	"text": `CellTable_text__7nNvI`,
	"header": `CellTable_header__gD5Iy`
};
export default ___CSS_LOADER_EXPORT___;
