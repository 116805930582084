// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppLink_AppLink__jPnt7 {
  position: relative;
  color: var(--White, #fff);
  font: var(--font-m);
  opacity: 0.5;
}
.AppLink_AppLink__jPnt7:hover {
  opacity: 1;
}
.AppLink_AppLink__jPnt7.AppLink_active__sD9a0 {
  opacity: 1;
}
.AppLink_AppLink__jPnt7.AppLink_active__sD9a0 .AppLink_textLink__r-ZKe::after {
  position: absolute;
  content: "";
  bottom: -4px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: var(--White, #fff);
  border-radius: 15px;
}

.AppLink_contentLink__hau4X {
  display: grid;
  grid-template-columns: 24px auto;
  align-items: center;
  column-gap: 8px;
}

.AppLink_textLink__r-ZKe {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/AppLink/ui/AppLink.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,UAAA;AACJ;AAEE;EACE,UAAA;AAAJ;AACI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,oCAAA;EACA,mBAAA;AACN;;AAIA;EACE,aAAA;EACA,gCAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;AADF","sourcesContent":[".AppLink {\n  position: relative;\n  color: var(--White, #fff);\n  font: var(--font-m);\n  opacity: 0.5;\n\n  &:hover {\n    opacity: 1;\n  }\n\n  &.active {\n    opacity: 1;\n    .textLink::after {\n      position: absolute;\n      content: \"\";\n      bottom: -4px;\n      left: 0;\n      height: 2px;\n      width: 100%;\n      background-color: var(--White, #fff);\n      border-radius: 15px;\n    }\n  }\n}\n\n.contentLink {\n  display: grid;\n  grid-template-columns: 24px auto;\n  align-items: center;\n  column-gap: 8px;\n}\n\n.textLink {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AppLink": `AppLink_AppLink__jPnt7`,
	"active": `AppLink_active__sD9a0`,
	"textLink": `AppLink_textLink__r-ZKe`,
	"contentLink": `AppLink_contentLink__hau4X`
};
export default ___CSS_LOADER_EXPORT___;
