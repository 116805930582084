// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_MainLayout__h1egE {
  display: flex;
  flex: 1 1;
  height: 100%;
}
.MainLayout_MainLayout__h1egE .MainLayout_main__bJoxh {
  flex-grow: 1;
  padding: 24px 34px 24px 24px;
  margin-left: calc(var(--sidbar-width) + 25px);
}`, "",{"version":3,"sources":["webpack://./src/shared/layouts/MainLayout/ui/MainLayout.module.scss"],"names":[],"mappings":"AAQA;EACE,aAAA;EACA,SAAA;EACA,YAAA;AAPF;AASE;EACE,YAAA;EACA,4BAfA;EAgBA,6CAfC;AAQL","sourcesContent":["$p: 24px 34px 24px 24px;\n$ml: calc(var(--sidbar-width) + 25px);\n\n@media screen and (max-width: 1500px) {\n  $p: 12px 30px 12px 12px;\n  $ml: calc(var(--sidbar-width) + 20px);\n}\n\n.MainLayout {\n  display: flex;\n  flex: 1;\n  height: 100%;\n\n  .main {\n    flex-grow: 1;\n    padding: $p;\n    margin-left: $ml;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainLayout": `MainLayout_MainLayout__h1egE`,
	"main": `MainLayout_main__bJoxh`
};
export default ___CSS_LOADER_EXPORT___;
