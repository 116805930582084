import { FC, SVGProps } from "react";

import { RoutePath } from "shared/config/routeConfig/routeConfig";

import { ReactComponent as IconTransactions } from "shared/assets/icons/transactions.svg";
import { ReactComponent as IconTransactionsActive } from "shared/assets/icons/transactions_active.svg";

import { ReactComponent as IconAnalytics } from "shared/assets/icons/analytics.svg";

export interface NabBarItemType {
  path: string;
  text: string;
  IconActive: FC<SVGProps<SVGSVGElement>>;
  Icon: FC<SVGProps<SVGSVGElement>>;
  testId?: string;
}

export const NabBarItemsList: NabBarItemType[] = [
  {
    path: RoutePath.analytics,
    Icon: IconAnalytics,
    IconActive: IconAnalytics,
    text: "Аналитика",
    testId: "link-analytics",
  },
  {
    path: RoutePath.transactions,
    Icon: IconTransactions,
    IconActive: IconTransactionsActive,
    text: "Транзакции",
    testId: "link-transactions",
  },
];
