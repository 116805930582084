// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RowTable_RowTable__ZBjio {
  display: flex;
  column-gap: 16px;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.RowTable_RowTable__ZBjio:last-of-type {
  margin-bottom: 0;
}

.RowTable_mainInfo__T51ol {
  width: 100%;
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 20px 24px;
  border-top: 1px solid rgba(1, 73, 99, 0.3);
}
.RowTable_mainInfo__T51ol.RowTable_active__Kfs12 {
  background: #d4e9f1;
}
.RowTable_mainInfo__T51ol:hover {
  background: #d4e9f1;
  cursor: pointer;
}

.RowTable_additionalInfo__uohzr {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.RowTable_wrapperBtnConfirm__gL2Ci {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  align-items: flex-start;
}

.RowTable_RowTable__ZBjio.RowTable_header__PqNQg {
  column-gap: 16px;
  flex-direction: row;
}
.RowTable_RowTable__ZBjio.RowTable_header__PqNQg .RowTable_mainInfo__T51ol {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/entities/Transactions/ui/TransactionTable/components/RowTable/RowTable.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,0CAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,eAAA;AADJ;;AAKA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;AAFF;;AAMA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,mBAAA;AAHF;AAKE;EACE,YAAA;AAHJ","sourcesContent":[".RowTable {\n  display: flex;\n  column-gap: 16px;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n\n.mainInfo {\n  width: 100%;\n  display: flex;\n  column-gap: 16px;\n  align-items: center;\n  padding: 20px 24px;\n  border-top: 1px solid rgba(1, 73, 99, 0.30);\n\n  &.active {\n    background: #d4e9f1;\n  }\n\n  &:hover {\n    background: #d4e9f1;\n    cursor: pointer;\n  }\n}\n\n.additionalInfo {\n  display: flex;\n  width: 100%;\n  overflow: hidden;\n}\n\n\n.wrapperBtnConfirm {\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  align-items: flex-start;\n}\n\n.RowTable.header {\n  column-gap: 16px;\n  flex-direction: row;\n\n  .mainInfo {\n    border: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RowTable": `RowTable_RowTable__ZBjio`,
	"mainInfo": `RowTable_mainInfo__T51ol`,
	"active": `RowTable_active__Kfs12`,
	"additionalInfo": `RowTable_additionalInfo__uohzr`,
	"wrapperBtnConfirm": `RowTable_wrapperBtnConfirm__gL2Ci`,
	"header": `RowTable_header__PqNQg`
};
export default ___CSS_LOADER_EXPORT___;
