// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideBar_SideBar__-7WyU {
  height: 100vh;
  width: calc(var(--sidbar-width) + 40);
  padding: var(--p-sidebar);
  position: fixed;
  z-index: 8;
}

.SideBar_content__bPG\\+- {
  background: var(--Dark, #014963);
  height: 100%;
  width: var(--sidbar-width);
  border-radius: 8px;
  box-shadow: 2px 4px 25px 0px rgba(1, 73, 99, 0.04);
  padding: 24px 24px 33px;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}
.SideBar_content__bPG\\+- .SideBar_top__uPJ98 {
  padding: 0 24px 26px;
}
.SideBar_content__bPG\\+- .SideBar_main__fLqc1 {
  padding: 24px 18px 24px 24px;
  border-bottom: 1px solid rgb(255, 255, 255);
}
.SideBar_content__bPG\\+- .SideBar_bottom__whXYn {
  padding: 24px;
}

@media screen and (max-width: 1600px) {
  .SideBar_SideBar__-7WyU .SideBar_main__fLqc1 {
    padding: 0px 18px 24px 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/SideBar/ui/SideBar/SideBar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,yBAAA;EACA,eAAA;EACA,UAAA;AACF;;AAEA;EACE,gCAAA;EACA,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,kDAAA;EACA,uBAAA;EACA,aAAA;EACA,+CAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;EACE,4BAAA;EACA,2CAAA;AAAJ;AAGE;EACE,aAAA;AADJ;;AAKA;EAEI;IACE,2BAAA;EAHJ;AACF","sourcesContent":[".SideBar {\n  height: 100vh;\n  width: calc(var(--sidbar-width) + 40);\n  padding: var(--p-sidebar);\n  position: fixed;\n  z-index: 8;\n}\n\n.content {\n  background: var(--Dark, #014963);\n  height: 100%;\n  width: var(--sidbar-width);\n  border-radius: 8px;\n  box-shadow: 2px 4px 25px 0px rgba(1, 73, 99, 0.04);\n  padding: 24px 24px 33px;\n  display: grid;\n  grid-template-rows: min-content 1fr min-content;\n\n  .top {\n    padding: 0 24px 26px;\n  }\n\n  .main {\n    padding: 24px 18px 24px 24px;\n    border-bottom: 1px solid rgba(255, 255, 255, 1);\n  }\n\n  .bottom {\n    padding: 24px;\n  }\n}\n\n@media screen and (max-width: 1600px) {\n  .SideBar {\n    .main {\n      padding: 0px 18px 24px 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SideBar": `SideBar_SideBar__-7WyU`,
	"content": `SideBar_content__bPG+-`,
	"top": `SideBar_top__uPJ98`,
	"main": `SideBar_main__fLqc1`,
	"bottom": `SideBar_bottom__whXYn`
};
export default ___CSS_LOADER_EXPORT___;
