import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "shared/api";
import { IReqGetBalanceByPeriod } from "shared/api/analytics/requests/getBalanceByPeriod";

export const getBalanceByPeriod = createAsyncThunk(
  "analytics/getBalanceByPeriod",
  async (params: IReqGetBalanceByPeriod["params"], thunkAPI) => {
    try {
      const res = await API.analytics.getBalanceByPeriod({ params });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);