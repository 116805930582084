import { AxiosResponse } from "axios";

import { SettingsAPI } from "..";
import { URL_GET_STATUSES } from "shared/config/apiConfig/apiConfig";

export interface IReqGetStatuses {
  isCache: boolean;
}

export function getStatuses(
  this: SettingsAPI,
  req: IReqGetStatuses
): Promise<AxiosResponse> {
  const authdata = localStorage.getItem("authdata");
  return this.axios.get(URL_GET_STATUSES, {
    headers: {
      Authorization: `Basic ${authdata}`,
    },
  });
}
