import { FC } from "react";
import classNames from "classnames";

import { useAppDispatch } from "shared/lib/hooks/useAppDispatch";
import { Icon } from "shared/ui/Icon";
import { ReactComponent as IconExit } from "shared/assets/icons/exit.svg";
import Button from "shared/ui/Button/ui/Button";

import { signOut } from "entities/User1/slices/userReducer";
import { useNavigate } from "react-router-dom";

import cls from "./ButtonExit.module.scss";
interface ButtonExitProps {
  className?: string;
}

const ButtonExit: FC<ButtonExitProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickBtnExit = () => {
    dispatch(signOut());
    navigate("sign-in");
  };

  return (
    <div className={classNames(cls.wrapperButtonExit, {}, [className])}>
      <Button className={cls.ButtonExit} onClick={handleClickBtnExit}>
        <Icon Svg={IconExit} className={cls.iconExit} />
        Выйти
      </Button>
      {/* <p className={cls.email}>rocketuser@rabbit.com</p> */}
    </div>
  );
};

export default ButtonExit;
