import { ListShowColumns } from "entities/Transactions/model/types/table";
import { ITransaction } from "entities/Transactions/model/types/ITransaction";

export class TableDataRules {
  [ListShowColumns.TRANSACTION_ID]: string;
  [ListShowColumns.STATUS]: string;
  [ListShowColumns.CREATED_DATE]: number | null;
  [ListShowColumns.DONE_DATE]: number | null;
  [ListShowColumns.AMOUNT]: number;
  [ListShowColumns.AMOUNT_USDT]: number;
  [ListShowColumns.WALLET_PROVIDER]: string;
  [ListShowColumns.WALLET]: string;
  [ListShowColumns.PAID_AMOUNT]: number;
  [ListShowColumns.DROP_NAME]: string;
  [ListShowColumns.MERCHANT_ID]: string;
  [ListShowColumns.TRADER_TG]: string;

  constructor({
    transactionId,
    status,
    createDate,
    doneDate,
    amount,
    amountUsdt,
    walletProvider,
    wallet,
    paidAmount,
    dropName,
    merchantId,
    traderTg,
  }: ITransaction) {
    this.transactionId = transactionId;
    this.createDate = createDate;
    this.amount = amount;
    this.status = status;
    this.doneDate = doneDate;
    this.amountUsdt = amountUsdt;
    this.walletProvider = walletProvider;
    this.wallet = wallet;
    this.paidAmount = paidAmount;
    this.dropName = dropName || "";
    this.merchantId = merchantId;
    this.traderTg = traderTg || "";
  }

  private getDoneDate(): string {
    return this.doneDate ? this.getCorrectDate(this.doneDate) : "";
  }

  private getCorrectTransactionId(): string {
    return this.transactionId;
  }

  private getCorrectAmount(): string {
    return String(this.amount);
  }

  private getCreateDate(): string {
    return this.createDate ? this.getCorrectDate(this.createDate) : "";
  }

  private getCorrectStatus(): string {
    return this.status;
  }

  private getCorrectDate = (timestamp: number) => {
    const data = new Date(timestamp);
    const currentDate = new Date(data);

    const correctElDate = (value: number) => {
      return value < 10 ? "0" + value : value;
    };

    const day = correctElDate(currentDate.getDate());
    const month = correctElDate(currentDate.getMonth() + 1);
    const year = correctElDate(currentDate.getFullYear());
    const hours = data.getHours();
    const minutes = correctElDate(data.getMinutes());

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  public getCorrectData(keyColumn: string): string {
    switch (keyColumn) {
      case ListShowColumns.TRANSACTION_ID:
        return this.getCorrectTransactionId();
      case ListShowColumns.CREATED_DATE:
        return this.getCreateDate();
      case ListShowColumns.AMOUNT:
        return this.getCorrectAmount();
      case ListShowColumns.DONE_DATE:
        return this.getDoneDate();
      case ListShowColumns.STATUS:
        return this.getCorrectStatus();
      case ListShowColumns.AMOUNT_USDT:
        return this.amountUsdt.toString();
      case ListShowColumns.WALLET_PROVIDER:
        return this.walletProvider;
      case ListShowColumns.WALLET:
        return this.wallet.replace(/(\d)(?=(\d{4})+(?!\d))/g, "$1 ");
      case ListShowColumns.PAID_AMOUNT:
        return `${this.paidAmount}`;
      case ListShowColumns.DROP_NAME:
        return this.dropName;
      case ListShowColumns.MERCHANT_ID:
        return this.merchantId;
      case ListShowColumns.TRADER_TG:
        return this.traderTg;
      default:
        return "";
    }
  }
}
