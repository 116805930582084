import { AxiosInstance, AxiosResponse } from "axios";
import { IReqCheckAuth, checkAuth } from "./requests/checkAuth";

export class UserAPI {
  protected axios: AxiosInstance;

  public checkAuth: (req: IReqCheckAuth) => Promise<AxiosResponse>;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.checkAuth = checkAuth.bind(this);
  }
}
